import { Injectable } from '@angular/core';
import { BaseService } from '@utils';
import { DashboardPageModel } from '@models';
import { Duration, Organization } from '@models';
@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseService {
  getVisitorCount(filter: Record<string, unknown>) {
    return this.httpClient.get<DashboardPageModel.VisitorCountResponse>(
      'visitors/visitorCountWithinDate',
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }
  getBotCount(filter: Record<string, unknown>) {
    return this.httpClient.get<DashboardPageModel.BotEngageTimeResponse>(
      'sessions/getSessionStatistics',
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }

  getNewVisitorCount(filter: Record<string, unknown>) {
    return this.httpClient.get<DashboardPageModel.NewVisitorCount>(
      'Queries/findDistinctUserCount',
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }
  getQueriesCount(orgId: string, filter: Record<string, unknown>) {
    return this.httpClient.get<DashboardPageModel.QueryCountResponse>(
      `/Organizations/${orgId}/queries/count`,
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }

  getQueriesByDuration(
    duration: Duration,
    organizationId?: Organization['id']
  ) {
    return this.httpClient.get<any[]>(`Queries/countQueriesByDate`, {
      params: this.convertToHttpParams({ duration, organizationId }),
    });
  }
  getLeadsByDuration(duration?: Duration, organizationId?: Organization['id']) {
    return this.httpClient.get<any[]>(`Leads/countLeadsByDate`, {
      params: this.convertToHttpParams({ duration, organizationId }),
    });
  }
  getLeadsBySource(filter: Record<string, unknown>, orgId: Organization['id']) {
    return this.httpClient.get<DashboardPageModel.LeadResponse>(
      `Organizations/${orgId}/leadsBySource`,
      {
        params: this.convertToHttpParams({ ...filter }),
      }
    );
  }
  getComplaintsCount(duration: Duration, organizationId: Organization['id']) {
    return this.httpClient.get<DashboardPageModel.ComplaintCountResponse[]>(
      `Complaints/countComplaintsByDate`,
      {
        params: this.convertToHttpParams({ duration, organizationId }),
      }
    );
  }
}
